<template>
  <section class="addAccount panel-section">
    <div class="back-btn" @click="back">
      <i class="el-icon-arrow-left"></i>
      <span>返回</span>
    </div>
    <el-form
      :model="formModel"
      label-position="left"
      status-icon
      :rules="rules"
      ref="formModel"
      label-width="132px"
      class="demo-formModel"
    >
      <div class="title">{{ titleContent }}客户备案</div>
      <el-form-item label="客户类型" prop="type">
        <el-select
          v-model="formModel.type"
          placeholder="请选择"
          class="com-width"
        >
          <el-option label="企业/机构" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="客户名称" prop="name">
        <el-input
          type="input"
          placeholder="请输入内容"
          v-model="formModel.name"
          class="com-width"
          maxlength="50"
        ></el-input>
      </el-form-item>
      <el-form-item label="统一机构代码" prop="creditCode">
        <el-input
          type="input"
          placeholder="请输入内容"
          v-model="formModel.creditCode"
          :disabled="isRead"
          class="com-width"
        ></el-input>
      </el-form-item>
      <el-form-item label="工作台账号" prop="workbenchAccount">
        <el-input
          type="input"
          placeholder="请输入11位手机号"
          v-model="formModel.workbenchAccount"
          maxlength="11"
          :disabled="isRead"
          class="com-width"
        ></el-input>
        <el-alert
          title="可手动为客户创建工作台账号，账号名为填入的手机号码，账号初始默认登录密码为lingshu@123"
          type="info"
          show-icon
          :style="{ marginTop: '10px' }"
          :closable="false"
        >
        </el-alert>
      </el-form-item>
      <el-form-item label="密钥方案" prop="escrowAccount">
        <el-select
          v-model="formModel.escrowAccount"
          placeholder="请选择"
          class="com-width"
        >
          <el-option label="平台托管" value="1"></el-option>
        </el-select>
      </el-form-item>
      <div class="title">{{ titleContent }}联系人信息</div>
      <el-form-item label="联系人姓名" prop="contact">
        <el-input
          type="input"
          placeholder="请输入联系人姓名"
          v-model="formModel.contact"
          class="com-width"
          maxlength="20"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone">
        <el-input
          type="input"
          placeholder="请输入联系电话"
          v-model="formModel.phone"
          class="com-width"
          maxlength="11"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系邮箱" prop="email">
        <el-input
          type="input"
          placeholder="请输入联系邮箱"
          v-model="formModel.email"
          class="com-width"
        ></el-input>
        <el-alert
          title="联系人电话和邮箱地址会用于接收系统的部分重要服务通知，请谨慎填写，以免影响到客户的业务使用。"
          type="info"
          show-icon
          :style="{ marginTop: '10px' }"
          :closable="false"
        >
        </el-alert>
      </el-form-item>
      <el-form-item>
        <el-button
          class="el-btn"
          type="primary"
          @click="submitForm('formModel')"
          >{{ titleContent }}</el-button
        >
        <el-button class="el-btn" @click="resetForm('formModel')">重置</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import API_ACCOUNT from "@/api/api_account";

//客户管理--新增客户
export default {
  name: "AddAccount",
  components: {},
  data() {
    return {
      isRead: false,
      formModel: {
        creditCode: undefined,
        name: undefined,
        type: "1",
        escrowAccount: "1",
        workbenchAccount: undefined,
        contact: undefined,
        phone: undefined,
        email: undefined,
      },
      rules: {
        type: { required: true, message: "请选择客户类型" },
        name: { required: true, message: "请输入客户名称" },
        creditCode: { required: true, message: "请输入统一机构代码" },
        escrowAccount: { required: true, message: "请选择密钥方案" },
        workbenchAccount: { required: true, message: "请输入11位手机号码" },
        contact: { required: true, message: "请输入联系人姓名" },
        phone: { required: true, message: "请输入联系电话" },
        email: { required: true, message: "请输入联系邮箱" },
      },
      id: "",
    };
  },
  computed: {
    ...mapGetters(["accountFillInfo"]),
    titleContent() {
      return this.id ? "更新" : "新增";
    },
  },
  methods: {
    ...mapMutations(["setAccountFillInfo"]),
    back() {
      this.$router.go(-1);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm(`确定${this.titleContent}客户备案吗？`, "提示", {
            type: "warning",
          })
            .then(() => {
              let isAdd = true;
              if (this.id) {
                this.formModel.id = this.id;
                isAdd = false;
              }
              API_ACCOUNT.setAccountFill(this.formModel, isAdd).then(
                ({ data }) => {
                  if (data.code == 200) {
                    this.$message({
                      message: `${this.titleContent}成功~`,
                      type: "success",
                    });
                    this.back();
                  } else {
                    this.$message.error({
                      message: data.msg || this.$CommonMsg["networkErr"],
                    });
                  }
                }
              );
            })
            .catch(() => {});
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(name) {
      if (this.id) {
        this.initAccountFill();
      } else {
        this.$refs[name].resetFields();
      }
    },
    initAccountFill() {
      if (this.id) {
        if (!this.accountFillInfo.id) {
          this.setAccountFillInfo(
            JSON.parse(sessionStorage.getItem("accountFillInfo") || "{}")
          );
        }
        this.formModel.creditCode = this.accountFillInfo.creditCode;
        this.formModel.name = this.accountFillInfo.name;
        this.formModel.workbenchAccount = this.accountFillInfo.workbenchAccount;
        this.formModel.contact = this.accountFillInfo.contact;
        this.formModel.phone = this.accountFillInfo.phone;
        this.formModel.email = this.accountFillInfo.email;
      } else {
        this.formModel = {
          creditCode: undefined,
          name: undefined,
          type: "1",
          escrowAccount: "1",
          workbenchAccount: undefined,
          contact: undefined,
          phone: undefined,
          email: undefined,
        };
        this.resetForm("formModel");
      }
    },
  },
  activated() {
    this.id = this.$route.query.id;
    this.isRead = Boolean(this.id);
    this.initAccountFill();
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/_basePage.scss";

.addAccount {
  padding: 40px 48px;
  .title {
    font-family: "Microsoft YaHei UI";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #303133;
    margin: 24px 0;
  }
}

.com-width {
  width: 430px !important;
  min-width: 300px !important;
}
/deep/ .el-alert {
  padding: 0 16px;
}
</style>
